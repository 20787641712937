var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-main" },
    [
      _vm.resetPasswordVisible
        ? _c("reset-password", {
            on: {
              closed: function ($event) {
                _vm.resetPasswordVisible = false
              },
            },
          })
        : _c("div", { staticClass: "login-container" }, [
            _c("div", { staticClass: "login" }, [
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v(" Welcome to Bunkerchain ")]),
              ]),
              _c(
                "div",
                { staticClass: "login-form" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.formData, rules: _vm.rules },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            required: "",
                            label: "Username",
                            prop: "username",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.formData.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "username", $$v)
                              },
                              expression: "formData.username",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Password",
                            required: "",
                            prop: "password",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "password", "show-password": "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.login.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.formData.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "password", $$v)
                              },
                              expression: "formData.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "remember-account" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { "margin-left": "5px" },
                                model: {
                                  value: _vm.rememberAccount,
                                  callback: function ($$v) {
                                    _vm.rememberAccount = $$v
                                  },
                                  expression: "rememberAccount",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("rememberAccount")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "forget-password",
                                on: {
                                  click: function ($event) {
                                    _vm.resetPasswordVisible = true
                                  },
                                },
                              },
                              [_vm._v(" Forget your password? ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "160px" },
                          attrs: {
                            type: "primary",
                            round: "",
                            loading: _vm.loginLoading,
                          },
                          on: { click: _vm.login },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("login")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
      _c(
        "div",
        {
          staticClass: "company-register",
          on: { click: _vm.toCompanyRegister },
        },
        [_vm._v(" " + _vm._s(_vm.$t("enterpriseRegister")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }